import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import useDateFns from '@hooks/useDateFns'

import { map as avatarMap } from '@tools/avatarColorMap'

const useStyles = makeStyles((theme) => ({
  userDetails: {
    display: 'flex',
    marginBottom: (props) =>
      props.isWrapped ? theme.spacing(5) : theme.spacing(8),
  },
  avatar: {
    marginRight: (props) =>
      props.isWrapped ? theme.spacing(3) : theme.spacing(4),
    borderRadius: 999,
    overflow: 'hidden',
    height: (props) => (props.isWrapped ? theme.spacing(6) : theme.spacing(7)),
    width: (props) => (props.isWrapped ? theme.spacing(6) : theme.spacing(7)),
    [theme.breakpoints.up('sm')]: {
      height: (props) =>
        props.isWrapped ? theme.spacing(6) : theme.spacing(15),
      width: (props) =>
        props.isWrapped ? theme.spacing(6) : theme.spacing(15),
    },
    textAlign: 'center',
    '& span': {
      maxWidth: '100%',
      height: 'auto',
      lineHeight: (props) =>
        props.isWrapped ? `${theme.spacing(5)}px` : `${theme.spacing(7)}px`,
      verticalAlign: 'center',
      fontSize: (props) => (props.isWrapped ? `18px` : `20px`),
      [theme.breakpoints.up('sm')]: {
        lineHeight: (props) =>
          props.isWrapped ? `${theme.spacing(5)}px` : `${theme.spacing(15)}px`,
        fontSize: (props) => (props.isWrapped ? `18px` : `40px`),
      },
    },
  },
  expertAvatar: {
    backgroundColor: 'white !important',
    backgroundImage: `url(/img/logo/5G_logo_icon.svg)`,
    backgroundSize: '65%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  postDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    '&:not($rowPostDetails)': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  },
  rowPostDetails: {
    flexDirection: 'row',
  },
  tagsWrapper: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(8),
  },
  name: {
    fontWeight: 700,

    '$rowPostDetails &': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
  },
  timestamp: {
    ...theme.typography.teaser,

    '$rowPostDetails &': {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  deletedHeader: {
    opacity: 0.2,
  },
  commentHeader: {
    '& $avatar': {
      height: theme.spacing(7),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(12),
        width: theme.spacing(12),
      },
      '& span': {
        lineHeight: `${theme.spacing(7)}px`,
        fontSize: '20px',
        [theme.breakpoints.up('sm')]: {
          lineHeight: `${theme.spacing(12)}px`,
          fontSize: '30px',
        },
      },
    },
  },
}))

function CardHeader({
  id,
  userName,
  userColor,
  timestamp,
  isExpert,
  isDeleted,
  isComment,
  isWrapped,
  hideTimestamp,
}) {
  const classes = useStyles({ isWrapped })

  const { formatDistanceToNow } = useDateFns()

  const userLetter = userName?.substr(0, 1).toUpperCase()
  const userColorsMapped =
    avatarMap[userColor ?? Math.floor(Math.random() * 10)]

  const expiredTime = formatDistanceToNow(timestamp)

  return (
    <div
      id={id}
      className={clsx(classes.userDetails, {
        [classes.deletedHeader]: isDeleted,
        [classes.commentHeader]: isComment,
      })}
    >
      <div
        className={clsx(classes.avatar, {
          [classes.expertAvatar]: isExpert,
        })}
        style={{
          backgroundColor: userColorsMapped?.background,
          color: userColorsMapped?.text,
        }}
      >
        {!isExpert && <span>{userLetter}</span>}
      </div>
      <div
        className={clsx(classes.postDetails, {
          [classes.rowPostDetails]: isWrapped && !hideTimestamp,
        })}
      >
        <div className={classes.name}>{userName}</div>
        {!hideTimestamp && (
          <div className={classes.timestamp}>{expiredTime}</div>
        )}
      </div>
    </div>
  )
}

CardHeader.propTypes = {
  id: PropTypes.string,
  userName: PropTypes.string,
  userColor: PropTypes.number,
  timestamp: PropTypes.string,
  isExpert: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isComment: PropTypes.bool,
  isWrapped: PropTypes.bool,
  hideTimestamp: PropTypes.bool,
}

export default CardHeader
