import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { Link } from 'gatsby'

// TODO: use input instead of button
// import PostInputField from '@objects/formFields/postInput'
import CreateContentButton from '@objects/button/createContentButton'
import LikeButton from '@objects/button/like'
import { ANSWER_SUBMIT_SUCCESS, eventBus } from '@services/eventBus'

const useStyles = makeStyles((theme) => ({
  metaBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  metaReplies: {
    display: 'inline-block',
    padding: 0,
    border: 'none',
    outline: 0,
    background: 'none',
    color: theme.palette.red.main,
    font: 'inherit',
    fontWeight: 700,
    '&.active': {
      cursor: 'pointer',
      ...theme.typography.link,
    },
  },
  link: {
    ...theme.typography.link,
  },
  readmore: {
    fontSize: '14px',
  },
}))

function CardMetaBar({
  postId,
  type,
  interactive,
  likeCount,
  replyCount,
  commentsCallback,
  textLink,
}) {
  const classes = useStyles()

  function renderAnswers() {
    if (textLink) {
      return !replyCount ? (
        <div className={classes.metaReplies}>
          <Link className={clsx(classes.link)} to={textLink}>
            <FormattedMessage id={'dialogue.noanswers'} />
          </Link>
        </div>
      ) : (
        <div>
          <div className={classes.metaReplies}>
            <Link className={clsx(classes.link)} to={textLink}>
              <FormattedMessage
                id={'dialogue.allAnswersCount'}
                values={{ count: replyCount }}
              />
            </Link>
          </div>
        </div>
      )
    }
    return !replyCount ? (
      <div className={classes.metaReplies}>
        <FormattedMessage id={'dialogue.noanswers'} />
      </div>
    ) : (
      <div>
        <div className={classes.metaReplies}>
          <FormattedMessage
            id={'dialogue.allAnswersCount'}
            values={{ count: replyCount }}
          />
        </div>
      </div>
    )
  }

  return interactive ? (
    /* CASE interactive post or answer card */
    <div className={classes.metaBar}>
      {'dialogue-full' === type ? (
        /* CASE post card with full post */
        <CreateContentButton
          color="red"
          type="secondary"
          contentType="createAnswer"
          articleId={postId}
          onSuccessCallback={(data) => {
            eventBus.dispatch(ANSWER_SUBMIT_SUCCESS, data)
          }}
        >
          <FormattedMessage id={'button.createAnswer'} />
        </CreateContentButton>
      ) : /* CASE answer card (answers = comments) */
      replyCount === 0 ? (
        <div className={classes.metaReplies}>
          <FormattedMessage id={'dialogue.nocomments'} />
        </div>
      ) : (
        <button
          className={clsx(classes.metaReplies, 'active')}
          onClick={commentsCallback}
          id={`answer-${postId}-control`}
        >
          <FormattedMessage
            id={'dialogue.comments'}
            values={{ count: replyCount }}
          />
        </button>
      )}

      <LikeButton
        type={'dialogue-full' === type ? 'article' : 'answer'}
        targetId={postId}
        likeCount={likeCount}
        interactive={interactive}
      />
    </div>
  ) : (
    /* CASE fully linked card in overviews */
    <div className={classes.metaBar}>
      <LikeButton
        type={'article'}
        targetId={postId}
        likeCount={likeCount}
        countAlign={'right'}
        interactive={false}
      />

      {renderAnswers()}
    </div>
  )
}

CardMetaBar.propTypes = {
  postId: PropTypes.number,
  type: PropTypes.oneOf([
    'default',
    'dialogue',
    'dialogue-full',
    'answer',
    'dialogue-answer',
  ]),
  interactive: PropTypes.bool,
  likeCount: PropTypes.number,
  replyCount: PropTypes.number,
  commentsCallback: PropTypes.func,
}

export default CardMetaBar
