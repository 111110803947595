import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import LoadingSkeleton from '@objects/loadingSkeleton'
import Card from '@objects/card'
import CardContent from './cardContent'
import DeletedElement from './deletedElement'

const useStyles = makeStyles((theme) => ({
  postCardRoot: {
    marginLeft: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  wrappedPostCardRoot: {
    margin: 0,
  },
  darkBackground: {
    background: theme.palette.background.grey,
    margin: theme.spacing(-5, -4),
    padding: theme.spacing(5, 4, 5, 4),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-8),
      padding: theme.spacing(8, 8, 6, 8),
    },
  },
  darkSmallBackground: {
    background: theme.palette.background.grey,
    margin: theme.spacing(-6, -8),
    padding: theme.spacing(6, 8, 6, 8),
  },
}))

function PostCard({
  className,
  children,
  postData,
  loading,
  type,
  bar,
  background,
  interactive,
  link,
  textLink,
  isWrapped,
  noMetaBar,
  answerBorder,
  baseLevel,
  ariaLabel,
  ...props
}) {
  const classes = useStyles()
  const commentsRef = useRef(null)
  const intl = useIntl()
  const [commentState, setCommentState] = useState({
    open: false,
    openStyle: {},
    maxHeight: 0,
  })
  const {
    id,
    likeCount,
    replyCount,
    userName,
    userColor,
    timestamp,
    topics,
    title,
    text,
    comments,
    isExpert,
    isEdited,
    editReason,
    lastEditedTimestamp,
    isDeleted,
    deleteType,
  } = postData

  const commentsCallback = () => {
    setCommentState((commentState) => ({
      ...commentState,
      open: !commentState.open,
    }))
  }

  useEffect(() => {
    const commentHeightCalculation = () => {
      if ('answer' === type && replyCount && commentsRef?.current) {
        commentsRef.current.style.maxHeight = 'none'
        const h = commentsRef.current.clientHeight
        commentsRef.current.style.maxHeight = 0
        setCommentState((commentState) => ({
          ...commentState,
          maxHeight: h + 64,
        }))
      }
    }

    commentHeightCalculation()
  }, [])

  useEffect(() => {
    setCommentState((commentState) => ({
      ...commentState,
      openStyle: {
        maxHeight: commentState.open ? commentState.maxHeight : 0,
      },
    }))
  }, [commentState.open])

  function getCardInner() {
    if (isWrapped) {
      if (type === 'dialogue-answer') {
        return 'H32V24'
      }
      return 'HV32'
    }
    return 'default'
  }
  return (
    <Card
      bar={bar}
      barOrientation={'right'}
      noMarginBottom={isWrapped}
      answerBorder={answerBorder}
      cardInner={getCardInner()}
      className={clsx(className, {
        [classes.postCardRoot]: !isWrapped,
        [classes.wrappedPostCardRoot]: isWrapped,
      })}
      link={type === 'dialogue' && !isDeleted ? link : null}
      {...props}
      role="listitem"
      aria-label={
        ariaLabel && `${intl.formatMessage({ id: ariaLabel })} - ${title}`
      }
    >
      <LoadingSkeleton loading={loading}>
        <div className={''}>
          <div
            className={clsx({
              [classes.darkBackground]: 'dark' === background,
              [classes.darkSmallBackground]: 'darkSmall' === background,
            })}
          >
            {isDeleted ? (
              <DeletedElement
                type={'answer' === type ? 'answer' : 'post'}
                deleteType={deleteType}
                userName={userName}
                userColor={userColor}
                timestamp={timestamp}
              />
            ) : (
              <CardContent
                commentsCallback={commentsCallback}
                commentState={commentState}
                setCommentState={setCommentState}
                commentsRef={commentsRef}
                replyCount={replyCount}
                likeCount={likeCount}
                userName={userName}
                userColor={userColor}
                timestamp={timestamp}
                isExpert={isExpert}
                isEdited={isEdited}
                editReason={editReason}
                lastEditedTimestamp={lastEditedTimestamp}
                topics={topics}
                title={title}
                text={text}
                id={id}
                interactive={interactive}
                type={type}
                comments={comments}
                isWrapped={isWrapped}
                noMetaBar={noMetaBar}
                textLink={textLink}
                baseLevel={baseLevel}
              />
            )}
          </div>
        </div>
      </LoadingSkeleton>
    </Card>
  )
}

PostCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  postData: PropTypes.shape({
    id: PropTypes.number,
    userName: PropTypes.string,
    userColor: PropTypes.number,
    likeCount: PropTypes.number,
    replyCount: PropTypes.number,
    topics: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    text: PropTypes.string,
    timestamp: PropTypes.string,
    comments: PropTypes.array,
    isDeleted: PropTypes.bool,
    deleteType: PropTypes.string,
    isExpert: PropTypes.bool,
  }),
  link: PropTypes.string,
  textLink: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'dialogue',
    'dialogue-full',
    'answer',
    'dialogue-answer',
  ]),
  bar: PropTypes.oneOf(['red', 'yellow', 'grey']),
  background: PropTypes.oneOf(['default', 'grey', 'dark', 'darkSmall']),
  interactive: PropTypes.bool,
  isWrapped: PropTypes.bool,
  noMetaBar: PropTypes.bool,
  answerBorder: PropTypes.oneOf(['none', 'single', 'double']),
}

PostCard.defaultProps = {
  type: 'default',
  background: 'default',
  bar: 'red',
  interactive: false,
  loading: false,
  isWrapped: false,
  noMetaBar: false,
  answerBorder: 'none',
  postData: {
    likeCount: 0,
    replyCount: 0,
  },
}

export default PostCard
