import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  topicRoot: {
    display: 'inline-block',
    background: theme.palette.background.grey,
    borderRadius: theme.spacing(3.5),
    padding: theme.spacing(0, 2.5),
    ...theme.typography.topic,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.red.main,
    fontWeight: 700,
    '&:focus': {
      color: theme.palette.text.invert,
      background: theme.palette.background.focus,
    },
    '&:hover': {
      color: theme.palette.red.light,
      background: theme.palette.grey.light,
    },
    '&.active': {
      color: theme.palette.text.primary,
      background: theme.palette.yellow.main,
    },
  },
}))

function Topic({ children, className, to, onClick }) {
  const classes = useStyles()

  function renderTopic() {
    if (to) {
      return (
        <Link
          data-track-content
          data-tracking-id={to}
          className={clsx(className, classes.topicRoot, classes.link)}
          to={to}
          onClick={onClick}
        >
          {children}
        </Link>
      )
    } else {
      return (
        <div
          className={clsx(className, classes.topicRoot, classes.link)}
          onClick={onClick}
        >
          {children}
        </div>
      )
    }
  }

  return renderTopic()
}

Topic.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

export default Topic
