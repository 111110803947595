import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@objects/icon'
import api from '@api'
import clsx from 'clsx'

import { formatISO, getUnixTime } from 'date-fns'

import { ARTICLE_LIKED, eventBus } from '@services/eventBus'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 0,
    border: 0,
    outline: 0,
    font: 'inherit',
    background: 'none',
    '&$reverse': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    '&$interactive': {
      cursor: 'pointer',
    },
    '&:focus $icon': {
      color: theme.palette.text.primary,
    },
  },
  count: {},
  interactive: {},
  icon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.red.main,
    lineHeight: 1,
  },
  reverse: {
    '& $icon': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(3),
    },
  },
  circle: {
    padding: theme.spacing(2.5),
    border: 0,
    borderRadius: '50%',
    background: theme.palette.red.main,
    color: theme.palette.text.invert,
    lineHeight: 0,
    '$root:focus > &': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    '$root &$active': {
      background: theme.palette.red.light,
    },
    '&$icon': {
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(3),
      },
    },
  },
  active: {},
}))

function LikeButton({ ...props }) {
  const [hover, setHover] = useState(false)
  const [liked, setLiked] = useState({})
  const classes = useStyles()
  const [iconName, setIconName] = useState('LikeOutline')
  const apiRequest = api()

  const {
    className,
    type,
    targetId,
    countAlign,
    likeCount,
    interactive,
    circle,
  } = props

  const onLike = () => {
    apiRequest
      .addLike(type, targetId)
      .then((response) => {
        const likeObj = {
          type,
          targetId,
          timestamp: formatISO(new Date()),
          likeCount: response?.data?.numberOfLikes,
        }

        localStorage.setItem(
          `${type}-${targetId}`,
          btoa(JSON.stringify(likeObj))
        )
        setLiked(likeObj)
      })
      .then(() => {
        eventBus.dispatch(ARTICLE_LIKED, targetId)
      })
      .catch((err) => {
        console.error('like error', err.message)
      })
  }

  function onLikeEvent(id) {
    if (id === targetId) {
      const localLike = localStorage.getItem(`${type}-${targetId}`)
      const likeObj = JSON.parse(atob(localLike))
      setLiked(likeObj)
    }
  }

  useEffect(() => {
    // check use like
    const localLike = localStorage.getItem(`${type}-${targetId}`)
    if (localLike) {
      const likeObj = JSON.parse(atob(localLike))

      // check cache timeout
      const timeExpired =
        (getUnixTime(new Date()) - getUnixTime(new Date(likeObj.timestamp))) /
          60 /
          15 >
        1

      if (timeExpired) {
        // use current
        likeObj.timestamp = formatISO(new Date())
        likeObj.likeCount = likeCount

        localStorage.setItem(
          `${type}-${targetId}`,
          btoa(JSON.stringify(likeObj))
        )
      }
      setLiked(likeObj)
    }
    if (!interactive && !circle) {
      liked.likeCount || likeCount > 0
        ? setIconName('Like')
        : setIconName('LikeOutline')
    }

    eventBus.on(ARTICLE_LIKED, onLikeEvent)
    return () => {
      eventBus.remove(ARTICLE_LIKED, onLikeEvent)
    }
  }, [targetId])

  useEffect(() => {
    if (!circle) {
      if (liked.likeCount || hover) {
        setIconName('Like')
      } else {
        setIconName('LikeOutline')
      }
    }
  }, [hover, liked])

  function renderIcon() {
    return (
      <>
        <span className={classes.count}>
          {liked.likeCount ?? (likeCount > 0 ? likeCount : '')}
        </span>{' '}
        <div
          className={clsx(classes.icon, {
            [classes.circle]: circle,
            [classes.active]: circle && (hover || liked.likeCount),
          })}
        >
          <Icon name={iconName} />
        </div>
      </>
    )
  }

  return interactive && !liked.likeCount ? (
    <button
      data-testid="likebutton"
      className={clsx(className, classes.root, classes.interactive, {
        [classes.reverse]: 'right' === countAlign,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onLike}
    >
      {renderIcon()}
    </button>
  ) : (
    <div
      data-testid="likebutton"
      className={clsx(className, classes.root, {
        [classes.reverse]: 'right' === countAlign,
      })}
    >
      {renderIcon()}
    </div>
  )
}

LikeButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['article', 'answer', 'comment']),
  targetId: PropTypes.number,
  likeCount: PropTypes.number,
  countAlign: PropTypes.oneOf(['left', 'right']),
  interactive: PropTypes.bool,
  circle: PropTypes.bool,
}

LikeButton.defaultProps = {
  countAlign: 'left',
}

export default LikeButton
