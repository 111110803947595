const createTheme = require('../../config/theme/index')
const theme = createTheme()

export const map = {
  1: {
    text: theme.palette.default.main,
    background: theme.palette.red.main,
  },
  2: {
    text: theme.palette.text.primary,
    background: theme.palette.red.main,
  },
  3: {
    text: theme.palette.default.main,
    background: theme.palette.red.light,
  },
  4: {
    text: theme.palette.text.primary,
    background: theme.palette.red.light,
  },
  5: {
    text: theme.palette.default.main,
    background: theme.palette.orange.main,
  },
  6: {
    text: theme.palette.text.primary,
    background: theme.palette.orange.main,
  },
  7: {
    text: theme.palette.default.main,
    background: theme.palette.yellow.main,
  },
  8: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  9: {
    text: theme.palette.default.main,
    background: theme.palette.grey.main,
  },
  10: {
    text: theme.palette.text.primary,
    background: theme.palette.grey.main,
  },

  A: {
    text: theme.palette.default.main,
    background: theme.palette.red.main,
  },
  B: {
    text: theme.palette.text.primary,
    background: theme.palette.red.main,
  },
  C: {
    text: theme.palette.default.main,
    background: theme.palette.red.main,
  },
  D: {
    text: theme.palette.text.primary,
    background: theme.palette.red.main,
  },
  E: {
    text: theme.palette.text.primary,
    background: theme.palette.red.main,
  },
  F: {
    text: theme.palette.text.primary,
    background: theme.palette.red.main,
  },
  G: {
    text: theme.palette.default.main,
    background: theme.palette.red.light,
  },
  H: {
    text: theme.palette.default.main,
    background: theme.palette.red.light,
  },
  I: {
    text: theme.palette.text.primary,
    background: theme.palette.red.light,
  },
  J: {
    text: theme.palette.text.primary,
    background: theme.palette.red.light,
  },
  K: {
    text: theme.palette.text.primary,
    background: theme.palette.red.light,
  },
  L: {
    text: theme.palette.text.primary,
    background: theme.palette.red.light,
  },
  M: {
    text: theme.palette.default.main,
    background: theme.palette.orange.main,
  },
  N: {
    text: theme.palette.text.primary,
    background: theme.palette.orange.main,
  },
  O: {
    text: theme.palette.text.primary,
    background: theme.palette.orange.main,
  },
  P: {
    text: theme.palette.text.primary,
    background: theme.palette.orange.main,
  },
  Q: {
    text: theme.palette.text.primary,
    background: theme.palette.orange.main,
  },
  R: {
    text: theme.palette.default.main,
    background: theme.palette.orange.main,
  },
  S: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  T: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  U: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  V: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  W: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  X: {
    text: theme.palette.text.primary,
    background: theme.palette.yellow.main,
  },
  Y: {
    text: theme.palette.default.main,
    background: theme.palette.grey.main,
  },
  Z: {
    text: theme.palette.text.primary,
    background: theme.palette.grey.main,
  },
}
