import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { Card, CardContent, CardHeader } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}))

function Question() {
  const classes = useStyles()
  return (
    <Card data-testid="question" className={classes.root}>
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={30}
            width="30%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={
          <Skeleton
            animation="wave"
            height={30}
            width="50%"
            style={{ marginBottom: 6 }}
          />
        }
      />
      <CardContent>
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
      </CardContent>
    </Card>
  )
}

export default Question
