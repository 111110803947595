import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'gatsby'

import Headline from '@objects/headline'
import CardHeader from './cardHeader'

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.h5,
  },
  link: {
    ...theme.typography.link,
  },
}))

function DeletedElement({ type, userName, userColor, timestamp, deleteType }) {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <>
      <CardHeader
        id={null}
        userName={userName}
        userColor={userColor}
        timestamp={timestamp}
        isDeleted={true}
      />
      <Headline level={2} className={classes.headline}>
        <FormattedMessage
          id={'dialogue.post.unavailable'}
          values={{
            type: intl.formatMessage({ id: `dialogue.type.${type}` }),
          }}
        />
      </Headline>
      <p>
        {deleteType === 'Netiquette' && (
          <FormattedMessage
            id={'dialogue.post.unavailableDescription'}
            values={{
              type: intl.formatMessage({ id: `dialogue.type.${type}` }),
              a: function netiquetteLink1(netiquette) {
                return (
                  <Link
                    className={classes.link}
                    to={intl.formatMessage({ id: 'netiquette.path' })}
                  >
                    {netiquette}
                  </Link>
                )
              },
            }}
          />
        )}
        {deleteType === 'DataProtection' && (
          <FormattedMessage id={'dialogue.post.unavailableByDataProtection'} />
        )}
      </p>
    </>
  )
}

DeletedElement.propTypes = {
  type: PropTypes.oneOf(['post', 'answer', 'comment']),
  userName: PropTypes.string.isRequired,
  userColor: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  deleteType: PropTypes.oneOf(['Netiquette', 'DataProtection']),
}

DeletedElement.defaultProps = {
  type: 'post',
  deleteType: 'Netiquette',
}

export default DeletedElement
